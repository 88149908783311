* {
  box-sizing: border-box;
  font-family: Manrope, "Roboto", "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f0f0f0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

a {
  transition: 0.05s all linear;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
