.table-wrapper {
  overflow-x: auto;
  display: inline-grid;
  max-width: 100%;
}

.table {
  table-layout: auto;
  white-space: normal;
  border: 0;
  border-spacing: 0;

  .tr {
    height: 45px;

    .th {
      background-color: var(--app-base-color);
      color: white;
      font-size: 13px;
      padding: 0 22px;

      &.centered {
        text-align: center;

        .sort {
          cursor: pointer;
          justify-content: center;
        }
      }

      &.right-aligned {
        text-align: right;
      }

      .sort {
        display: flex;
        align-items: center;

        button {
          font-size: 12px;
          padding: 0 7px;
          min-width: unset;
          background-color: transparent;
          color: white;
        }
      }
    }

    .td {
      padding: 8px 22px;
      font-size: 14px;
      border-bottom: 0;

      &.centered {
        text-align: center;
      }

      &.highlight {
        color: var(--app-accent-color);
      }

      &.actions {
        display: flex;
        justify-content: flex-end;

        button {
          min-width: unset;
          padding: 0;

          &.edit {
            color: var(--app-accent-color);
            margin-right: 15px;
          }

          &.delete {
            color: #ff3434;
          }
        }
      }
    }

    &:nth-child(2n) {
      .td {
        background-color: var(--app-accent-color-alpha);
      }
    }
  }
}

mat-paginator {
  color: gray;
  font-size: 13px;
}
