/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/index.scss";
@import "ngx-toastr/toastr";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: #111313;
  font-family: Manrope, "Helvetica Neue", sans-serif;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}
.no-padding-dialog .mat-dialog-content {
  margin: 0;
  padding: 0 16px;
}

.curved-dialog .mat-dialog-container {
  border-radius: 20px;
}
